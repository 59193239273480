import { Controller } from '@hotwired/stimulus'
import Cookies from 'js-cookie'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static values = {
    listsIndexUrl: String,
    loaded: Boolean
  }

  connect () {
    this.element['listsPopupController'] = this
    this.systemDrawer = document.getElementById('system-drawer')

    this.boundOnHide = this.onHide.bind(this)
    this.systemDrawer.addEventListener('sl-hide', this.boundOnHide)

    this.boundHideAllTooltips = this.hideAllTooltips.bind(this)
    this.systemDrawer.addEventListener(
      'sl-after-show',
      this.boundHideAllTooltips
    )

    // HACK: we don't want the user touch-scrolling the background while the drawer is open, so we
    // are aggressively forcing the drawer to close on any touchstart or mousedown event outside it
    //
    // (this may or may not be an issue with the sl-drawer component, but it's a problem for us in this case)
    useClickOutside(this, {
      element: this.systemDrawer.querySelector('.drawer-content'),
      dispatchEvent: false,
      events: ['mousedown', 'touchstart']
    })
  }

  disconnect () {
    this.systemDrawer.removeEventListener('sl-hide', this.boundOnHide)
    this.systemDrawer.removeEventListener(
      'sl-after-show',
      this.boundHideAllTooltips
    )
  }

  clickOutside (event) {
    // HACK: prevent the drawer from closing when the user clicks on the drawer itself
    if (
      event
        .composedPath()
        .map(el => el.className)
        .includes('drawer__panel')
    )
      return

    this.systemDrawer.hide()
  }

  triggerDrawer (event) {
    event.stopPropagation()
    event.preventDefault()

    const userSignedIn = Cookies.get('user_signed_in') === 'true'

    if (!userSignedIn) {
      window.location.href = '/join'
    } else if (this.systemDrawer.open) {
      this.systemDrawer.hide()
    } else {
      this.systemDrawer.label = 'My Lists'

      if (document.querySelector('html').clientWidth < 768) {
        // mobile
        this.systemDrawer.placement = 'bottom'
      } else {
        // desktop
        this.systemDrawer.placement = 'end'
      }

      this.refreshListsTool()
      this.systemDrawer.show()
    }
  }

  hideAllTooltips () {
    document.querySelectorAll('sl-tooltip').forEach(el => {
      el.hide()
    })
  }

  onHide () {
    this.loadedValue = false
  }

  refreshListsTool () {
    if (!this.loadedValue) {
      fetch(this.listsIndexUrlValue)
        .then(r => r.json())
        .then(data => {
          CableReady.performAsync(data).then(() => {
            this.loadedValue = true
          })
        })
    }
  }

  handleUserListsUpdate () {
    this.dispatch('updated')

    // sets all list popups to 'loaded=false' so they will be refreshed on next open
    document
      .querySelectorAll('[data-user-lists-popup-component]')
      .forEach(el => {
        el.listsPopupController.loadedValue = false
      })

    // go ahead and refresh myself since i'm already open
    this.refreshListsTool()
  }
}
