import { Controller } from '@hotwired/stimulus'
import { decode } from 'js-base64'
import { useHotkeys } from 'stimulus-use/hotkeys'

export default class extends Controller {
  static values = {
    startingPoints: String,
    startingZoom: Number,
    longitude: Number,
    latitude: Number,
    mapType: { type: String, default: 'Standard' },
    draggable: Boolean
  }
  static classes = ['open', 'closed']
  static targets = [
    'map',
    'maximizeButton',
    'minimizeButton',
    'placeMarkerTemplate',
    'gastroMarkerTemplate',
    'clusterTemplate',
    'calloutTemplate'
  ]

  async connect () {
    await fetch('/aon/map/token', {
      method: 'GET',
      headers: {
        'Content-Type': 'text/plain'
      }
    })
      .then(response => {
        return response.text()
      })
      .then(data => {
        this.mapTokenValue = data
      })

    this.initMap()
  }

  disconnect () {}

  async setupMapKit () {
    if (!window.mapkit || window.mapkit.loadedLibraries.length === 0) {
      await new Promise(resolve => {
        window.initMapKit = resolve
      })

      delete window.initMapKit
    }

    if (!window.mapKitInitialized) {
      mapkit.init({
        authorizationCallback: done => {
          done(this.mapTokenValue)
        }
      })

      window.mapKitInitialized = true
    }
  }
  async initMap () {
    await this.setupMapKit()

    const module = await import('supercluster')
    this.supercluster = module.default

    useHotkeys(this, {
      esc: [this.escapeHandler]
    })

    this.refreshMap()
  }

  escapeHandler () {
    if (this.element.classList.contains(...this.openClasses)) {
      this.minimizeMap()
    }
  }

  refreshMap () {
    this.loadSupercluster()
    this.showMap()
  }

  loadSupercluster () {
    const geoJsonPoints = []

    this.scIndex = new this.supercluster({
      radius: 120,
      maxZoom: 16,
      minPoints: 3
    })

    const points = JSON.parse(decode(this.startingPointsValue))

    points.forEach(point => {
      if (point.latitude && point.longitude) {
        geoJsonPoints.push({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [point.longitude, point.latitude]
          },
          properties: {
            id: point.id,
            title: point.title,
            gastro: point.gastro
          }
        })
      }
    })

    this.scIndex.load(geoJsonPoints)
  }

  _equals (a, b) {
    if (a === b) return true

    if (a instanceof Date && b instanceof Date)
      return a.getTime() === b.getTime()

    if (!a || !b || (typeof a !== 'object' && typeof b !== 'object'))
      return a === b

    if (a.prototype !== b.prototype) return false

    const keys = Object.keys(a)
    if (keys.length !== Object.keys(b).length) return false

    return keys.every(k => this._equals(a[k], b[k]))
  }

  _annotationId (c) {
    return c.properties.cluster_id
      ? `c-${c.properties.cluster_id}`
      : `p-${c.properties.id}`
  }

  annotateMap (bigMap, zoomLevel) {
    const bounds = bigMap.region.toBoundingRegion()
    const newClusters = this.scIndex.getClusters(
      [
        bounds.westLongitude,
        bounds.southLatitude,
        bounds.eastLongitude,
        bounds.northLatitude
      ],
      zoomLevel
    )
    if (!this.currentZoomLevel) this.currentZoomLevel = zoomLevel

    let zoomed = this.currentZoomLevel != zoomLevel

    let clustersToAdd = []
    if (!this.currentAnnotationIds) {
      this.currentAnnotationIds = []
      clustersToAdd = newClusters
    } else {
      // ignore clusters that are already annotated on the map
      clustersToAdd = newClusters.filter(
        c => !this.currentAnnotationIds.includes(this._annotationId(c))
      )
    }

    // **** ADD ANNOTATIONS ****
    if (clustersToAdd.length > 0) {
      const newAnnotations = clustersToAdd.map(c => this._createAnnotation(c))
      bigMap.addAnnotations(newAnnotations)
    }

    // **** REMOVE ANNOTATIONS (only if we've changed zoom level) ****
    if (zoomed && this.annotations) {
      const annosToRemove = this.annotations.filter(
        a =>
          !newClusters
            .map(c => this._annotationId(c))
            .includes(a.data.annotationId)
      )
      bigMap.removeAnnotations(annosToRemove)
    }

    this.annotations = bigMap.annotations
    this.currentAnnotationIds = bigMap.annotations.map(a => a.data.annotationId)
    this.currentZoomLevel = zoomLevel
  }

  _createAnnotation (cluster) {
    if (cluster.properties.point_count) {
      const coord = new mapkit.Coordinate(
        parseFloat(cluster.geometry.coordinates[1]),
        parseFloat(cluster.geometry.coordinates[0])
      )

      const node = this.clusterTemplateTarget.content.firstElementChild.cloneNode(
        true
      )
      const count = cluster.properties.point_count.toString()
      const circleSize = 40 + Math.min(40, cluster.properties.point_count / 10)

      let fontSize = 14
      switch (count.length) {
        case 1:
          fontSize = 14
          break
        case 2:
          fontSize = 16
          break
        case 3:
          fontSize = 18
          break
        case 4:
          fontSize = 20
          break
        default:
          fontSize = 14
      }

      node.querySelector('.cluster-count').innerText = count
      node.style.height = `${circleSize}px`
      node.style.width = `${circleSize}px`
      node.style.fontSize = `${fontSize}px`

      node.dataset.latitude = coord.latitude
      node.dataset.longitude = coord.longitude

      const clusterAnnotation = new mapkit.Annotation(coord, () => node, {
        animates: false,
        data: {
          annotationId: `c-${cluster.id}`,
          clusterId: cluster.id,
          isCluster: true
        }
      })

      return clusterAnnotation
    } else {
      const coord = new mapkit.Coordinate(
        parseFloat(cluster.geometry.coordinates[1]),
        parseFloat(cluster.geometry.coordinates[0])
      )

      let calloutDelegate = {
        calloutElementForAnnotation: annotation => {
          return this.fetchCallout(cluster.properties.id)
        },
        calloutAnchorOffsetForAnnotation: (annotation, size) => {
          return new DOMPoint(0, -(size['height'] + 40))
        },
        calloutShouldAnimateForAnnotation: annotation => {
          return false
        }
      }

      let node
      if (cluster.properties.gastro === true) {
        node = this.gastroMarkerTemplateTarget.content.firstElementChild.cloneNode(
          true
        )
      } else {
        node = this.placeMarkerTemplateTarget.content.firstElementChild.cloneNode(
          true
        )
      }

      node.setAttribute('title', cluster.properties.title)

      const childAnnotation = new mapkit.Annotation(coord, () => node, {
        animates: false,
        callout: calloutDelegate,
        data: {
          annotationId: `p-${cluster.properties.id}`,
          isCluster: false
        }
      })

      return childAnnotation
    }
  }

  fetchCallout (placeId) {
    const node = this.calloutTemplateTarget.content.firstElementChild.cloneNode(
      true
    )
    let url = node.dataset.url.replace('ID', placeId)

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        this.dispatchGaEventOnPinClick(placeId)
        node.innerHTML = data.html
      })

    return node
  }

  dispatchGaEventOnPinClick (placeId) {
    this.dispatch('userEvent', {
      prefix: 'analytics',
      detail: {
        handler: 'handleBasicGaEvent',
        data: {
          gaAction: 'Clicked Pin: ' + placeId,
          gaCategory: 'Dynamic Map',
          gaLabel: '@PATH@'
        }
      }
    })
  }

  _mapkitMapType () {
    switch (this.mapTypeValue) {
      case 'standard':
        return mapkit.Map.MapTypes.Standard
      case 'hybrid':
        return mapkit.Map.MapTypes.Hybrid
      case 'satellite':
        return mapkit.Map.MapTypes.Satellite
      case 'muted-standard':
        return mapkit.Map.MapTypes.MutedStandard
      default:
        return mapkit.Map.MapTypes.Standard
    }
  }

  showMap () {
    this.mapTarget.innerHTML = ''

    const center = new mapkit.Coordinate(
      this.latitudeValue,
      this.longitudeValue
    )

    const coords = this.scIndex.points.map(p => {
      let c = p.geometry.coordinates
      return { latitude: parseFloat(c[1]), longitude: parseFloat(c[0]) }
    })

    const opts = {
      mapType: this._mapkitMapType(),
      isScrollEnabled: this.draggableValue,
      padding: new mapkit.Padding({ top: 14, right: 14, bottom: 14, left: 14 }),
      showsMapTypeControl: false,
      pointOfInterestFilter: mapkit.PointOfInterestFilter.including([
        'Aquarium',
        'Hotel',
        'Library',
        'Museum',
        'NationalPark',
        'Park',
        'Zoo'
      ])
    }

    if (coords.length === 1) {
      // If only 1 point provided, we're just creating a map centered
      // on that point, so we don't need to calculate deltas or anything
      // just set the center and a fixed zoom level
      opts.center = center
      this.bigMap = new mapkit.Map(this.mapTarget, opts)
      this.bigMap._impl.zoomLevel = this.startingZoomValue
    } else {
      const deltas = this._calcDeltas(coords)

      const span = new mapkit.CoordinateSpan(
        deltas.latitudeDelta,
        deltas.longitudeDelta
      )

      const region = new mapkit.CoordinateRegion(center, span)

      opts.region = region

      // clear event listeners
      if (this.bigMap) this._clearMapEventListeners()

      // create new map
      this.bigMap = new mapkit.Map(this.mapTarget, opts)
    }

    this._setMapEventListeners()

    this.annotateMap(this.bigMap, this._zoomLevel())
  }

  _setMapEventListeners () {
    this.bigMap.addEventListener('region-change-start', ev => {
      const boundHeartbeat = function () {
        let map = ev.target

        this.annotateMap(map, this._zoomLevel())
      }.bind(this)

      this.mapChangeHeartbeat = setInterval(boundHeartbeat, 50)
    })

    this.bigMap.addEventListener('region-change-end', () => {
      this.annotateMap(this.bigMap, this.bigMap._impl.zoomLevel)

      clearInterval(this.mapChangeHeartbeat)
    })

    this.bigMap.addEventListener('select', event => {
      let annotation = event.annotation

      if (annotation.data.isCluster) {
        let expZoomLevel = this.scIndex.getClusterExpansionZoom(
          annotation.data.clusterId
        )

        let coord = new mapkit.Coordinate(
          annotation.coordinate.latitude,
          annotation.coordinate.longitude
        )

        this._centerAndZoomTo(coord, expZoomLevel)
      } else {
        this._enlargeMarker(annotation)
      }
    })

    this.bigMap.addEventListener('deselect', event => {
      const annotation = event.annotation

      if (!annotation.data.isCluster) {
        annotation.element.classList.remove('scale-125')
      }
    })
  }

  _clearMapEventListeners () {
    this.bigMap.removeEventListener('region-change-start')
    this.bigMap.removeEventListener('region-change-end')
    this.bigMap.removeEventListener('select')
    this.bigMap.removeEventListener('deselect')
  }

  _enlargeMarker (annotation) {
    annotation.element.classList.add('scale-125')
  }

  _zoomLevel () {
    return this.bigMap._impl.zoomLevel
  }

  _centerAndZoomTo (coord, level) {
    // Ensure the coord is a valid mapkit.Coordinate object
    if (!(coord instanceof mapkit.Coordinate)) {
      coord = new mapkit.Coordinate(coord.latitude, coord.longitude)
    }

    this.bigMap.setCenterAnimated(coord, true)
    if (level > this.bigMap._impl.zoomLevel) this.bigMap._impl.zoomLevel = level
  }

  _calcDeltas (points) {
    // points should be an array of { latitude: X, longitude: Y }
    let minX,
      maxX,
      minY,
      maxY

      // init first point
    ;(point => {
      minX = point.latitude
      maxX = point.latitude
      minY = point.longitude
      maxY = point.longitude
    })(points[0])

    // calculate rect
    points.map(point => {
      minX = Math.min(minX, point.latitude)
      maxX = Math.max(maxX, point.latitude)
      minY = Math.min(minY, point.longitude)
      maxY = Math.max(maxY, point.longitude)
    })

    const midX = (minX + maxX) / 2
    const midY = (minY + maxY) / 2
    const deltaX = maxX - minX
    const deltaY = maxY - minY

    return {
      latitude: midX,
      longitude: midY,
      latitudeDelta: deltaX,
      longitudeDelta: deltaY
    }
  }

  maximizeMap () {
    let container = this.element

    container.classList.remove(...this.closedClasses)
    container.classList.add(...this.openClasses)

    this.maximizeButtonTarget.classList.add('hidden')
    this.minimizeButtonTarget.classList.remove('hidden')
  }

  minimizeMap () {
    let container = this.element

    container.classList.remove(...this.openClasses)
    container.classList.add(...this.closedClasses)

    this.maximizeButtonTarget.classList.remove('hidden')
    this.minimizeButtonTarget.classList.add('hidden')
  }
}
