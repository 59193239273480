import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = ['caption']

  connect () {
    import('@splidejs/splide').then(module => {
      this.splide = module.default
      this.initPageCarousel()
      this.initPhotoGallery()
    })
  }

  initPhotoGallery () {
    this.thumbnailCarousel = new this.splide(
      this.element.querySelector('#modal-thumbnail-carousel'),
      {
        autoWidth: true,
        autoHeight: true,
        gap: 10,
        rewind: false,
        pagination: false,
        focus: 'center',
        drag: false,
        isNavigation: true,
        arrows: false,
        speed: 0
      }
    )

    this.modalCarousel = new this.splide(
      this.element.querySelector('#modal-image-carousel'),
      {
        type: 'fade',
        speed: 0,
        pagination: false,
        arrows: true,
        lazyLoad: 'nearby'
      }
    )

    this.modalCarousel.sync(this.thumbnailCarousel)
    this.modalCarousel.mount()
    this.thumbnailCarousel.mount()
  }

  initPageCarousel () {
    const elem = this.element.querySelector('#image-carousel')

    this.carousel = new this.splide(elem, {
      type: 'slide',
      start: 0,
      gap: '0.25em',
      height: this.element.dataset.carouselHeight,
      pagination: false,
      focus: 0,
      autoWidth: true,
      trimSpace: false,
      drag: false,
      isNavigation: false,
      lazyLoad: 'nearby',
      preloadPages: 3
    }).mount()

    this.carousel.on(
      'move',
      function (newIndex) {
        if (this.captionTargets && this.captionTargets.length > 0) {
          this.captionTargets.forEach(cap => {
            cap.classList.add('invisible')
          })
          this.captionTargets[newIndex].classList.remove('invisible')
        }
      }.bind(this)
    )

    this.carousel.on(
      'drag',
      function () {
        if (this.captionTargets && this.captionTargets.length > 0) {
          this.captionTargets.forEach(cap => {
            cap.classList.add('invisible')
          })
        }
      }.bind(this)
    )

    this.carousel.on(
      'click',
      function (Slide, e) {
        if (e.target.tagName !== 'A') {
          e.preventDefault()
          this.modalCarousel.go(Slide.index)
          this.showGallery()
        }
      }.bind(this)
    )
  }

  showGallery () {
    const modal = this.element.querySelector('sl-dialog#photo-gallery-modal')
    modal.noHeader = true
    modal.show()
  }

  hideGallery () {
    const modal = this.element.querySelector('sl-dialog#photo-gallery-modal')
    modal.hide()
  }

  disconnect () {
    this.carousel.destroy()
  }
}
